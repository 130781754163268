import React, { useEffect, useState } from "react";
import { getServicesConfig } from "./store";

function useConfig() {
  const [config, setConfig] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    handleGetServicesConfig();
  }, []);

  const handleGetServicesConfig = async () => {
    try {
      const response = await getServicesConfig();
      if (response) {
        setConfig(response);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return { config, error };
}

export default useConfig;
