import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import ReferralsList from "./ReferralsList";
import { enableServiceDirectory } from "../../../utils/constants";
import styles from "../../../styles/viewer.module.css";
import ReferralsAPI from "../../../services/ReferralsAPI";
import { Context } from "../../../store/AppStore";
import { ReferralActions, ReferralActionButton } from "./ReferralActions";
import { ReferralDialog } from "./ReferralDialog";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../theme";
import { Container } from "@material-ui/core";
import auth from "../../../utils/auth";
import ServicesSelectionDialog from "./ServicesSelectionDialog";
import { getServicesConfig } from "../../../firebase/store";
import { ReferralAddendumDialog } from "./addendum";

const ReferralsViewer = (props) => {
  const history = useHistory();
  const [, dispatch] = useContext(Context);
  const { patientId } = useParams();
  const [referrals, setReferrals] = useState(null);
  const [referralDialogOpen, setReferralDialogOpen] = useState(false);
  const [isAddendumDialogOpen, setIsAddendumDialogOpen] = useState(false);
  const [organizations, setOrganizations] = useState(null);
  const [error, setError] = useState("");
  const [servicesSelectionDialogOpen, setServicesSelectionDialogOpen] =
    useState(false);
  const [servicesConfig, setServicesConfig] = useState(null);

  const showAllReferrals = props.showAllReferrals && auth.canSeeAllReferrals;

  const loadReferralData = useCallback(() => {
    ReferralsAPI.provisionUser(auth)
      .then(() => {
        if (!showAllReferrals) {
          loadReferralCatalogs();
        }
        ReferralsAPI.getReferrals(props.showAllReferrals ? undefined : patientId)
          .then((referrals) => {
            setReferrals(referrals);
          })
          .catch((error) => {
            setError(error.message);
          });
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [patientId, props.showAllReferrals, showAllReferrals]);

  const loadReferralCatalogs = () => {
    ReferralsAPI.getOrganizations().then(organizations => {
      sessionStorage.setItem("organizations", JSON.stringify(organizations));
      setOrganizations(organizations);
    });
  };

  useEffect(() => loadReferralData(), [patientId, loadReferralData]);

  useEffect(() => {
    if (enableServiceDirectory) {
      handleGetServicesConfig();
    }
  }, []);

  const handleGetServicesConfig = async () => {
    const conf = await getServicesConfig();
    console.log("DEBUG servicesConfig: ", conf);
    if (conf) {
      setServicesConfig(conf);
    }
  };

  const handleReferralClick = (referral) => {
    if (referral) {
      dispatch({ type: "UPDATE_REFERRAL", payload: referral });
      if (props.showAllReferrals) {
        history.push(`/referrals/${referral.referralId}`);
      } else {
        history.push(
          `/patient/search/${patientId}/info/1/referrals/${referral.referralId}`
        );
      }
    }
  };

  const handleNewReferralClick = () => {
    setReferralDialogOpen(true);
  };

  const showAddendumDialog = () => {
    setIsAddendumDialogOpen(true);
  }

  const hideAddendumDialog = () => {
    setIsAddendumDialogOpen(false);
  }

  const handleReferralDialogClosed = () => {
    setReferralDialogOpen(false);
  };

  const handleServicesSelectionDialogClick = () => {
    setServicesSelectionDialogOpen(true);
  };

  const handleServicesSelectionDialogClosed = () => {
    setServicesSelectionDialogOpen(false);
  };

  const handleReferralSave = async (referral) => {
    try {
      await ReferralsAPI.createReferral(referral);
      await loadReferralData();
      setReferralDialogOpen(false);
      return "";
    } catch (error) {
      return error.message;
    }
  };

  const addReferralAddendum = async (addendum) => {
    try {
      await ReferralsAPI.addReferralAddendum({
        patientId,
        ...addendum,
      });
      await loadReferralData();
      setIsAddendumDialogOpen(false);
      return "";
    } catch (error) {
      return error.message;
    }
  };

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>{error}</Container>
      </ThemeProvider>
    );
  }
  return (
    <Fragment>
      {organizations && (
        <>
          <ReferralDialog
            patientId={patientId}
            open={referralDialogOpen}
            handleSave={handleReferralSave}
            handleClose={handleReferralDialogClosed}
          />
          <ReferralAddendumDialog
            open={isAddendumDialogOpen}
            handleSave={addReferralAddendum}
            handleClose={hideAddendumDialog}
          />
        </>
      )}
      {enableServiceDirectory && servicesConfig && (
        <ServicesSelectionDialog
          open={servicesSelectionDialogOpen}
          handleClose={handleServicesSelectionDialogClosed}
          config={servicesConfig}
          handleSave={handleReferralSave}
        />
      )}
      <div className={styles.documentViewer}>
        {referrals &&
          organizations &&
          !props.showAllReferrals && (
            <ReferralActions>
              <ReferralActionButton
                text="Create Referral"
                action={handleNewReferralClick}
              />
              <ReferralActionButton
                text="Create Addendum"
                action={showAddendumDialog}
              />
              {enableServiceDirectory && servicesConfig && organizations ? (
                <ReferralActionButton
                  text="Search Directory"
                  action={handleServicesSelectionDialogClick}
                />
              ) : null}
            </ReferralActions>
          )}
        <ReferralsList
          showAllReferrals={showAllReferrals}
          referrals={referrals}
          onReferralClick={handleReferralClick}
        />
      </div>
    </Fragment>
  );
};

export default withRouter(ReferralsViewer);
