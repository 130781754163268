// import { referralsUrl } from "../utils/constants";
import { ErrorUtils } from "../utils/exceptions";
import { getServicesConfig } from "../firebase/store";
import {
  enableServiceDirectory,
  fhirReplacementUrl,
  fhirUrlsToReplace,
} from "../utils/constants";
import { get } from "lodash";
import axiosInstance from "../utils/FhirAxiosHelper";

// const axiosInstance = ErrorUtils.configureAxiosForMintErrors(
//   AxiosHelper.newInstance()
// );

export default class FhirReferralsAPI {
  static baseUrl = null;

  static async setBaseUrl() {
    this.baseUrl = await this.getFhirBaseUrlFromFirebase();
  }

  static async getBaseUrl() {
    if (!this.baseUrl) {
      await this.setBaseUrl();
    }
    return this.baseUrl;
  }

  static async getFhirBaseUrlFromFirebase() {
    if (!enableServiceDirectory) return null;
    try {
      const conf = await getServicesConfig();
      if (conf?.connection?.baseUrl) {
        console.log("DEBUG servicesConfig: ", conf);
        return conf.connection.baseUrl;
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  static async getReferrals(
    patientId,
    status,
    modifiedSince,
    entireOrganization
  ) {
    console.log("DEBUG getReferrals patientId: ", patientId);
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/ServiceRequest`;
    const params = new URLSearchParams();

    if (patientId) {
      params.append("patient.identifier", patientId);
      params.append("_include", "ServiceRequest:performer");
      params.append("_include", "ServiceRequest:requester");
      params.append("_include", "ServiceRequest:subject");
    }
    const result = await axiosInstance.get(url, {
      params,
    });
    // console.log("DEBUG getReferrals response data: ", result?.data);
    const referralsData = { referrals: [], organizations: [], patient: null };
    if (result?.data?.entry) {
      console.log("debug searching referrals");
      referralsData.referrals = result.data.entry
        .filter((r) => r?.resource?.resourceType === "ServiceRequest")
        .map((r) => r?.resource);
      referralsData.organizations = result.data.entry
        .filter((r) => r?.resource?.resourceType === "Organization")
        .map((r) => r?.resource);
      if (patientId) {
        referralsData.patient = await this.getPatientByIdentifier(patientId);
      }
    } else if (patientId) {
      console.log("debug searching patient");
      referralsData.patient = await this.getPatientByIdentifier(patientId);
    }
    // console.log("DEBUG referralsData: ", referralsData);
    console.log("DEBUG referralsData: ", referralsData);
    return referralsData;
  }

  static async getPatientByIdentifier(patientIdentifier) {
    console.log("DEBUG getPatientByIdentifier: ", patientIdentifier);
    try {
      const baseUrl = await this.getBaseUrl();
      const url = `${baseUrl}/Patient?identifier=${patientIdentifier}`;
      console.log("DEBUG patientUrl: ", url);
      const result = await axiosInstance.get(url);
      const foundPatients = result.data.entry
        .filter((r) => r?.resource?.resourceType === "Patient")
        .map((r) => r?.resource);
      if (foundPatients && foundPatients.length > 0) {
        return foundPatients[0];
      }
    } catch (error) {
      console.log("DEBUG failed fetching patient");
      return null;
    }
    return null;
  }

  static async getReferral(referralId, includeRelated = false) {
    if (includeRelated) {
      const url = `${await this.getBaseUrl()}/ServiceRequest?_id=${referralId}&_include=ServiceRequest:subject&_include=ServiceRequest:performer`;
      const result = await axiosInstance.get(url);
      // console.log("DEBUG referralData: ", result?.data);
      const data = {
        referral: result?.data?.entry?.find(
          (d) => d?.resource?.resourceType === "ServiceRequest"
        ),
        patient: result?.data?.entry?.find(
          (d) => d?.resource?.resourceType === "Patient"
        ),
        organizations: result?.data?.entry
          ?.filter((d) => d?.resource?.resourceType === "Organization")
          .map((r) => r?.resource),
      };
      return data;
    } else {
      const url = `${await this.getBaseUrl()}/ServiceRequest/${referralId}`;
      const result = await axiosInstance.get(url);
      // console.log("DEBUG referralData: ", result?.data);
      return result?.data;
    }
  }

  static async getOrganizations() {
    const url = `${await this.getBaseUrl()}/Organization`;
    const params = {};
    // if (patientId) {
    //   params["patient.identifier"] = patientId;
    //   params["_include"] = "ServiceRequest:performer";
    // }
    const result = await axiosInstance.get(url, {
      params,
    });
    // console.log("DEBUG getOrganizations response data: ", result?.data);
    const data = { organizations: [] };
    if (result?.data) {
      data.organizations = result.data.entry
        .filter((r) => r?.resource?.resourceType === "Organization")
        .map((r) => r?.resource);
    }
    return data;
  }

  static async createReferral(payload) {
    const url = `${await this.getBaseUrl()}/ServiceRequest`;
    const result = await axiosInstance.post(url, payload);
    console.log("DEBUG createReferral response data: ", result?.data);
    return result?.data;
  }

  static async updateReferral(referralId, payload) {
    const url = `${await this.getBaseUrl()}/ServiceRequest/${referralId}`;
    const result = await axiosInstance.put(url, payload);
    console.log("DEBUG updateReferral response data: ", result?.data);
    return result?.data;
  }

  static async patchReferral(referralId, payload) {
    const url = `${await this.getBaseUrl()}/ServiceRequest/${referralId}`;
    const result = await axiosInstance.patch(url, payload);
    console.log("DEBUG updateReferral response data: ", result?.data);
    return result?.data;
  }

  static async getServiceCategories() {
    const url = `${await this.getBaseUrl()}/ValueSet/ocp-service-category`;
    const params = {};
    // if (patientId) {
    //   params["patient.identifier"] = patientId;
    //   params["_include"] = "ServiceRequest:performer";
    // }
    const result = await axiosInstance.get(url, {
      params,
    });
    // console.log("DEBUG getServiceCategories response data: ", result?.data);
    const data = { serviceCategories: [] };
    if (result?.data?.compose?.include?.[0]?.concept) {
      data.serviceCategories = result.data.compose.include[0].concept;
    }
    return data;
  }

  static async getCommunications(partOf) {
    try {
      const url = `${await this.getBaseUrl()}/Communication?part-of=${partOf}`;
      const result = await axiosInstance.get(url);
      const found = result.data.entry.map((r) => r?.resource);
      if (found && found.length > 0) {
        return found;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  static async getDocumentReferences(query) {
    try {
      const url = `${await this.getBaseUrl()}/DocumentReference${query}`;
      const result = await axiosInstance.get(url);
      const found = result.data.entry.map((r) => r?.resource);
      if (found && found.length > 0) {
        return found;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  static async getDocumentReference(
    docReferenceId,
    rawCCD = false,
    embedBinary = false
  ) {
    try {
      // const url = `${await this.getBaseUrl()}/DocumentReference?_id=${docReferenceId}`;
      const url = `${await this.getBaseUrl()}/DocumentReference/${docReferenceId}`;
      const result = await axiosInstance.get(url);

      const document = result?.data;

      if (embedBinary) {
        let binaryUrl = result?.data?.content?.[0]?.attachment?.url;
        if (!result?.data?.content?.[0]?.attachment?.data && binaryUrl) {
          console.log("DEBUG fhirUrlsToReplace: ", fhirUrlsToReplace);
          const matchedUrl = fhirUrlsToReplace.filter((f) => {
            console.log(
              `DEBUG ${binaryUrl} includes ${f}?: `,
              binaryUrl.includes(f)
            );
            return binaryUrl.includes(f);
          })?.[0];
          console.log("DEBUG matchedUrl: ", matchedUrl);
          if (matchedUrl) {
            binaryUrl = binaryUrl.replace(matchedUrl, fhirReplacementUrl);
            console.log("DEBUG replacedUrl: ", binaryUrl);
          }
          // const fullBinaryUrl = binaryUrl?.replace()
          console.log("DEBUG attempting to retrieve binary from: ", binaryUrl);
          const binaryResult = await axiosInstance.get(binaryUrl);
          if (binaryResult?.data?.data) {
            document.content[0].attachment.data = binaryResult?.data?.data;
            console.log("DEBUG binaryData: ", binaryResult?.data);
            console.log("DEBUG embedded binary to document: ", document);
          }
        }
      }

      return document;
    } catch (error) {
      console.log(error);
      const message = get(error, "error.response.data.message", error.message);
      return { error: message };
    }
  }

  static async UpdateDocumentReference(docReferenceId, payload) {
    console.log("DEBUG: UpdateDocumentReference init");
    const url = `${await this.getBaseUrl()}/DocumentReference/${docReferenceId}`;
    const result = await axiosInstance.put(url, payload);
    console.log("DEBUG UpdateDocumentReference response data: ", result?.data);
    return result?.data;
  }

  // static async linkDocument(referralId, fhirDocument) {
  //   const result = await axiosInstance.post(
  //     `${referralsUrl}/${referralId}/document`,
  //     {
  //       retrievalParameters: ReferralUtils.getRetrievalParameters(fhirDocument),
  //     }
  //   );
  //   return result?.data;
  // }

  /* export const uploadDocument = async ({
    patientID, fhirDocument, file, onUploadProgress
  }) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append('patientID', patientID);
    formData.append('fhirDocument', JSON.stringify(fhirDocument));
    return axiosInstance.post(
      `${mintProxyUrl}/api/DocumentReference/upload`,
      formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
  }; */

  static async createNote(payload) {
    const url = `${await this.getBaseUrl()}/Communication`;
    const result = await axiosInstance.post(url, payload);
    console.log("DEBUG createNote response data: ", result?.data);
    return result?.data;
  }
}
