import React from 'react';
import { Button, Grid } from '@material-ui/core';
import auth from '../../../utils/auth';
import styles from '../../../styles/viewer.module.css';

export const ReferralActions = ({ children }) => {
  return (
    <Grid container spacing={2} className={styles.leftButtonContainer}>
      {children}
    </Grid>
  );
};

export const ReferralActionButton = ({ text, action, canShow, referral, patientInfo, color, disabled = false }) => {
  if (!referral || (referral && canShow(auth, referral, patientInfo))) {
    return (
      <Grid item>
        <Button variant="contained" color={color || 'primary'} onClick={action} disabled={disabled}>
          {text}
        </Button>
      </Grid>
    );
  }
  return null;
};
