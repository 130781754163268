import auth from "./auth";
import axios from "axios";
import { fhirTokenUrl } from "./constants";
import Cookies from "js-cookie";

export const FHIR_COOKIE_KEY = "fhir-cookie";

const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
  },
};

const defaultFhirOptions = {
  headers: {
    "Content-Type": "application/fhir+json",
  },
};

async function getFhirAccessToken() {
  const accessToken = auth.token();
  console.log("DEBUG auth access token: ", accessToken);

  let headers = defaultOptions;

  if (accessToken) {
    headers["Authorization"] = accessToken;
  }

  let res;

  try {
    res = await axios.get(fhirTokenUrl, {
      headers: headers,
    });
  } catch (e) {
    console.log(e);
  }

  console.log("DEBUG getFhirAccessToken response: ", res);

  if (res?.data?.accessToken) {
    return res.data;
  }

  return undefined;
}

export function fhirCookieExists() {
  return Cookies.get(FHIR_COOKIE_KEY) ? true : false;
}

export function getFhirCookie() {
  return Cookies.get(FHIR_COOKIE_KEY);
}

async function setFhirCookie() {
  const response = await getFhirAccessToken();

  if (
    response === undefined ||
    response.accessToken === undefined ||
    response.exp === undefined
  )
    return;

  const expDate = new Date(response.exp * 1000);

  Cookies.set(FHIR_COOKIE_KEY, response.accessToken, {
    expires: expDate,
  });
}

console.log("DEBUG new axios instance");
const axiosInstance = axios.create({
  ...defaultFhirOptions,
});

axiosInstance.interceptors.request.use(
  async function (config) {
    console.log("DEBUG interceptor");
    if (!fhirCookieExists()) {
      await setFhirCookie();
    }

    if (config.headers === undefined) {
      config.headers = {};
    }

    config.headers["Authorization"] = "Bearer " + getFhirCookie();
    return config;
  },
  function (error) {
    console.log("DEBUG interceptor failed");
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosInstance;
