import { get } from 'lodash';
import auth from "../utils/auth";

function getOrganizationName(auth) {
  return get(auth, 'loggedInUser.userinfo.orgName');
}

export function isReferringOrganization(auth, referral) {
  return getOrganizationName(auth) === referral.referringOrganization;
}

export function isReceivingOrganization(auth, referral) {
  return getOrganizationName(auth) === referral.receivingOrganization
}

export function getPatientId(auth, referral) {
  return isReferringOrganization(auth, referral)
    ? referral.referringPatientId
    : referral.receivingPatientId || referral.referringPatientId
}

function isReferringUser(auth, referral) {
  return (
    isReferringOrganization(auth, referral)
    && auth.mintUserId === referral.referringMintUserId
  );
}

export function getReferralPatientId (referral) {
  if (isReferringOrganization(auth, referral)) {
    return referral.referringPatientId;
  }
  if (isReceivingOrganization(auth, referral)) {
    return referral.receivingPatientId;
  }
  return null;
}

function isPatientProvisioned(referral) {
  return referral.receivingPatientId;
}

function isOpen (referral) {
  return (
    [
      ReferralStates.DRAFT,
      ReferralStates.SUBMITTED,
      ReferralStates.ACTIVE,
      ReferralStates.ON_HOLD,
    ]
      .includes(referral.status)
  );
}

export default class ReferralStates {

  static DRAFT = 'draft';
  static SUBMITTED = 'submitted';
  static ACTIVE = 'active';
  static ON_HOLD = 'on-hold';
  static COMPLETED = 'completed';
  static REVOKED = 'revoked';

  static canWithdraw(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferringUser(auth, referral)
      && isOpen(referral)
    );
  }

  static canSubmit(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferringOrganization(auth, referral)
      && referral.status === ReferralStates.DRAFT
    )
  }

  static canUpdate(auth, referral, patientInfo) {
    return (
      patientInfo
      && isOpen(referral)
    );
  }

  static canAddNote(auth, referral, patientInfo) {
    return (
      patientInfo
      && isOpen(referral)
      && isPatientProvisioned(referral)
    );
  }

  static canAccept(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && referral.status === ReferralStates.SUBMITTED
    )
  }

  static canNotifyInfoProvided(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferringOrganization(auth, referral)
      && referral.status === ReferralStates.ON_HOLD
    )
  }

  static canReject(auth, referral) {
    return (
      isReceivingOrganization(auth, referral)
      && [ReferralStates.ACTIVE, ReferralStates.ON_HOLD].includes(referral.status)
    )
  }

  static canReopen(auth, referral) {
    return referral.status === ReferralStates.COMPLETED;
  }

  static canComplete(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && referral.status === ReferralStates.ACTIVE
    )
  }

  static canRequestMissingInfo(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && referral.status === ReferralStates.ACTIVE
    )
  }

  static canAssign(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && [ReferralStates.ACTIVE, ReferralStates.ON_HOLD].includes(referral.status)
    )
  }

  static canCreateChild(auth, referral, patientInfo) {
    return ReferralStates.canAssign(auth, referral, patientInfo);
  }

  static canProvision(auth, referral) {
    return (
      isReceivingOrganization(auth, referral)
      && isOpen(referral)
      && !isPatientProvisioned(referral)
    )
  }

  static canAddDocument(auth, referral) {
    return isOpen(referral);
  }
}
