import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import AddCircle from "@material-ui/icons/AddCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentUtils, ReferralUtils } from "../../../services";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  list: {
    width: 398,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    minWidth: 400,
  },
  searchTextInputBorder: {
    display: "flex",
    alignItems: "center",
    width: 400,
    marginTop: theme.spacing(1),
  },
  searchTextInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  tooltipItemTitle: {
    fontVariantCaps: "small-caps",
    marginRight: "10px",
  },
  tooltipItemCell: {
    paddingTop: "4px",
    paddingBottom: "4px",
    borderTop: "1px solid rgba(255, 255, 255, 0.54)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.54)",
    verticalAlign: "middle",
  },
}));

export const TransferBox = ({
  title,
  documents,
  enableSearch,
  handleToggle,
  selected,
  linked,
  onUploadDocumentRequested,
  enableUpload,
}) => {
  const classes = useStyles();

  const TooltipItems = ({ children }) => (
    <table style={{ marginTop: "5px", marginBottom: "5px" }}>{children}</table>
  );

  const TooltipItem = ({ title, text }) =>
    text ? (
      <tr>
        <td className={classes.tooltipItemCell} style={{ paddingLeft: "5px" }}>
          <span className={classes.tooltipItemTitle}>{title}:</span>
        </td>
        <td className={classes.tooltipItemCell} style={{ paddingRight: "5px" }}>
          {text}
        </td>
      </tr>
    ) : (
      <Fragment />
    );

  return (
    <Fragment>
      <div
        style={{
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          marginBottom: enableSearch ? "-10px" : "8px",
          marginTop: enableSearch ? "0" : "8px",
        }}
      >
        {title}
        {enableUpload ? (
          <IconButton
            aria-label="Add Document"
            color="primary"
            component="span"
            onClick={onUploadDocumentRequested}
          >
            <AddCircle />
          </IconButton>
        ) : null}
      </div>
      {/* {enableSearch ? (
      <FormGroup row>
        <FormControl className={classes.formControl}>
          <Paper className={classes.searchTextInputBorder} variant="outlined">
            <InputBase
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              disabled={documents.length === 0}
              className={classes.searchTextInput}
              inputProps={{ "aria-label": "search documents" }}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
            <IconButton
              aria-label="clear-search-term"
              className={classes.searchIconButton}
              onClick={clearSearchTerm}
            >
              <CloseIcon />
            </IconButton>
          </Paper>
        </FormControl>
      </FormGroup>
    ) : null} */}
      <Card variant="outlined">
        <List
          dense
          className={classes.list}
          style={{ height: enableSearch ? "350px" : "396px" }}
          component="div"
          role="list"
        >
          {documents
            // .filter((document) =>
            //   documentMatchesSearchTerm(document, enableSearch)
            // )
            // .sort(DocumentUtils.documentCreationDateComparator)
            .map((document) => {
              // const docKey = DocumentUtils.getKey(document);
              const docKey = document.id;
              const labelId = `transfer-list-all-document-${docKey}-label`;
              const docInfo = DocumentUtils.getDisplayInfo(document);
              return (
                <ListItem
                  key={docKey}
                  role="listitem"
                  button
                  disableRipple
                  disabled={linked.indexOf(document) >= 0}
                  onClick={handleToggle(document)}
                  selected={selected.indexOf(document) !== -1}
                >
                  <Tooltip
                    title={
                      <TooltipItems>
                        <TooltipItem
                          title="Content Type"
                          text={docInfo.contentType}
                        />
                        <TooltipItem title="Size" text={docInfo.size} />
                        <TooltipItem title="Author" text={docInfo.author} />
                        <TooltipItem
                          title="Description"
                          text={docInfo.description}
                        />
                        <TooltipItem
                          title="Facility Type"
                          text={docInfo.facilityType}
                        />
                        <TooltipItem
                          title="Practice Setting"
                          text={docInfo.practiceSetting}
                        />
                        <TooltipItem title="Category" text={docInfo.category} />
                        <TooltipItem
                          title="Format Code"
                          text={docInfo.formatCode}
                        />
                        <TooltipItem title="Doc Key" text={docKey} />
                      </TooltipItems>
                    }
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={docInfo.iconName}
                        size="2x"
                        style={{ color: "#006181", marginLeft: "10px" }}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    id={labelId}
                    primary={
                      <div style={{ fontWeight: "bold", fontSize: "smaller" }}>
                        {docInfo.title}
                      </div>
                    }
                    secondary={
                      <Fragment>
                        <div
                          style={{
                            color: "black",
                            fontStyle: "italic",
                            fontSize: "smaller",
                          }}
                        >
                          {docInfo.type}
                        </div>
                        <div style={{ color: "#006181", fontSize: "x-small" }}>
                          {docInfo.creationDate}
                        </div>
                      </Fragment>
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      </Card>
    </Fragment>
  );
};
