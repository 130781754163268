import {
  organizationsUrl,
  referralsFormApiUrl,
  referralsUrl,
  serviceCategoriesUrl,
} from "../utils/constants";
import { get, sortBy } from "lodash";
import { ErrorUtils } from "../utils/exceptions";
import { ReferralUtils } from "./ReferralUtils";
import { AxiosHelper } from "../utils/AxiosHelper";

const axiosInstance = ErrorUtils.configureAxiosForMintErrors(AxiosHelper.newInstance());

export default class ReferralsAPI {

  static async getReferrals(patientId, status, modifiedSince, entireOrganization) {
    const result = await axiosInstance.get(
      referralsUrl,
      {
        params: {
          patientId,
          status,
          modifiedSince,
          entireOrganization
        }
      }
    );
    return ErrorUtils.toEmptyArray(result?.data, true);
  }

  static async getReferral(referralId, fullDemographics) {
    const result = await axiosInstance.get(
      `${referralsUrl}/${referralId}/detail`
    );
    return result?.data;
  }

  static async addNote(referralId, noteText) {
    const result = await axiosInstance.post(
      `${referralsUrl}/${referralId}/notes`,
      {
        note: noteText
      },
    )
    return result?.data;
  }

  static async addDocumentReference(referralId, annotation, documentUrl) {
    const result = await axiosInstance.post(
      `${referralsUrl}/${referralId}/document-reference`,
      {
        annotation,
        documentUrl,
      },
    )
    return result?.data;
  }

  static async linkDocument(referralId, fhirDocument) {
    const result = await axiosInstance.post(
      `${referralsUrl}/${referralId}/document`,
      {
        retrievalParameters: ReferralUtils.getRetrievalParameters(fhirDocument)
      },
    )
    return result?.data;
  }

  static async createReferral(referral) {
    const result = await axiosInstance.post(
      referralsUrl,
      referral,
    )
    return result?.data;
  }

  static async addReferralAddendum(addendum) {
    const result = await axiosInstance.post(
      `${referralsUrl}/addendum`,
      addendum,
    )
    return result?.data;

  }

  static async createPreventionLinkReferral(patientId, preventionLinkForm) {
    const result = await axiosInstance.post(
      `${referralsUrl}/prevention-link?patientId=${patientId}`,
      preventionLinkForm,
    )
    return result?.data;
  };

  static async getServiceCategories(orgId) {
    return sortBy(
      ErrorUtils.toEmptyArray(await axiosInstance.get(`${serviceCategoriesUrl}/${orgId}`))
        .filter(category => category.description !== '')
        .map(category => category.referralServiceCategory)
      , (category) => category.toUpperCase()
    );
  }

  static async getOrganizations() {
    return sortBy(
      ErrorUtils.toEmptyArray(await axiosInstance.get(organizationsUrl)),
      (organization) => organization?.organizationName?.toUpperCase(),
    );
  }

  static async updateReferral(referralId, referralUpdates) {
    await axiosInstance.put(`${referralsUrl}/${referralId}`, referralUpdates);
  }

  static async acceptReferral(referralId, comment) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/accept`, { comment });
  }

  static async notifyReferralInfoProvided(referralId, comment) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/notify-info-provided`, { comment });
  }

  static async rejectReferral(referralId, comment) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/reject`, { comment });
  }

  static async withdrawReferral(referralId, comment) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/withdraw`, { comment });
  }

  static async reopenReferral(referralId, comment) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/reopen`, { comment });
  }

  static async submitReferral(referralId, comment) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/submit`, { comment });
  }

  static async assignReferral(referralId, mintUserId) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/assign`, { mintUserId });
  }

  static async completeReferral(referralId, comment) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/complete`, { comment });
  }

  static async requestMissingInfoForReferral(referralId, comment) {
    await axiosInstance.put(`${referralsUrl}/${referralId}/request-missing-info`, { comment });
  }

  static async deleteReferralAttachment(attachment) {
    await axiosInstance.delete(`${referralsUrl}/attachments/${attachment.type}/${attachment.id}`);
  }

  static async provisionUser(auth) {
    console.log("ReferralsAPI.provision()")
    if (!auth.provisioned) {
      const result = await axiosInstance.put(`${referralsUrl}/${auth.username}/provision`);
      auth.orgId = get(result, 'data.authUniversalId');
      auth.mintUserId = get(result, 'data.mintUserId');
      auth.provisioned = true;
    }
  }

  static async provisionReferralPatient(auth, referral) {
    const result = await axiosInstance.put(
      `${referralsUrl}/${auth.username}/${referral.referralId}/provision`,
      referral
    );
    return result?.data;
  }

  static async getReferralFormSize(formUUID) {
    const result = await axiosInstance.get(
      `${referralsFormApiUrl}/size/${formUUID}`
    )
    return result?.data;
  }

  static async getPreventionLinkReferralDefaults() {
    const result = await axiosInstance.get(
      `${referralsUrl}/defaults/prevention-link`
    )
    return result?.data;
  }

  static async getFacilityNames(orgId) {
    if (!orgId) {
      return [];
    }
    const result = await axiosInstance.get(
      `${referralsUrl}/catalogs/facility-names/${orgId}`
    )
    return result?.data;
  }

  static async getOrganizationMembers(orgName) {
    const result = await axiosInstance.get(
      `${referralsUrl}/catalogs/members/${orgName}`
    )
    return result?.data;
  }
}
