import React, { useState, useEffect } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import PatientTable from './PatientTable'
import PatientFilters from './PatientFilters'
import { getPatients, provisionPatient } from '../../services/MintAPI';
import getPatientsList from '../../services/PatientsMock'
import { mockData } from '../../utils/constants'
import Container from '@material-ui/core/Container';
import styles from '../../styles/viewer.module.css';
import NewPatientDialog from '../PatientViewer/NewPatientDialog';
import { useHistory, useLocation } from 'react-router-dom'

const theme = createMuiTheme({
  overrides: {}
})

const PatientSearch = ({
  handlePatientOnClick,
  auth
}) => {
  const [patients, setPatients] = useState([])
  const [initialListState, setInitialListState] = useState([])
  const [filter, setFilter] = useState({
    patientId: '',
    birthDate: '',
    firstName: '',
    lastName: ''
  })
  const [ error, setError ] = useState('');
  const [showNewPatientDialog, setShowNewPatientDialog] = useState(false);
  const [prefilledPatient, setPrefilledPatient] = useState(null)
  const location  = useLocation();
  const history = useHistory()

  useEffect(() => {
    console.log("DEBUG location: ", location)
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('createPatient') && queryParams.get('createPatient')) {
      // console.log("DEBUG: createPatient: ", queryParams.get('createPatient'))
      // setPrefilledPatient(auth.patient)
      // console.log("DEBUG token patient: ", auth.patient)
      setPrefilledPatient(auth.patient)
      // setShowNewPatientDialog(true)
      // queryParams.delete('createPatient')
      // history.replace({
        // search: queryParams.toString(),
      // })
    }
  }, [location])

  useEffect(() => {
    if(prefilledPatient){
      const queryParams = new URLSearchParams(location.search)
      if (queryParams.has('createPatient') && queryParams.get('createPatient')) {
        console.log("DEBUG: createPatient: ", queryParams.get('createPatient'))
        // setPrefilledPatient(auth.patient)
        setShowNewPatientDialog(true)
        console.log("DEBUG token patient: ", auth.patient)
        queryParams.delete('createPatient')
        history.replace({
          search: queryParams.toString(),
        })
      }
    }
  }, [prefilledPatient])
  

  const handleOnFilter = (data) => {
    setFilter({
      ...filter,
      [data.target.id]: data.target.value
    })
  }

  const handleSearchPatient = async () => {
    setInitialListState(null)
    let patientData;
    if(mockData) {
      patientData = getPatientsList(filter)
    } else {
      try {
        patientData = await getPatients(filter)
        setPatients(patientData)
        setInitialListState(patientData)
      }
      catch (error) {
        setError(error.message);
      }
    }
  }

  const handleNewPatientSave = async (fhirPatient, mrn) => {
    try {
      await provisionPatient(fhirPatient, mrn)
      setShowNewPatientDialog(false);
      return '';
    }
    catch (error) {
      return error.message;
    }
  }

  const handleNewPatient = () => {
    setShowNewPatientDialog(true);
  }

  const handleNewPatientClose = () => {
    setShowNewPatientDialog(false);
    setPrefilledPatient(null)
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>
          {error}
        </Container>
      </ThemeProvider>
    )
  }
  return(
    <ThemeProvider theme={theme}>
      {(showNewPatientDialog && auth) ? (
        <NewPatientDialog
        open={showNewPatientDialog}
        handleSave={handleNewPatientSave}
        handleClose={handleNewPatientClose}
        prefilledPatient={prefilledPatient}
        auth={auth}
      />
      ) : null}
      
      <Grid container>
        <PatientFilters
          filter={filter}
          handleChange={handleOnFilter}
          handleSearchPatient={handleSearchPatient}
          handleNewPatient={handleNewPatient}
        />
        <PatientTable
          data={patients} onPatientClick={handlePatientOnClick}
          initialState={initialListState}
        />
      </Grid>
    </ThemeProvider>
  )
}

export default PatientSearch
