import React, { useContext, useState } from "react";
import ReferralDocumentList from './ReferralDocumentList';
import { DocumentUtils } from '../../../services';
import { Context } from '../../../store/AppStore';
import { useHistory } from 'react-router-dom';
import { getReferralPatientId } from "../../../services/ReferralStates";

export default function ReferralDocuments({ referral, patientId, onDocumentDelete }) {

  const history = useHistory()
  const dispatch = useContext(Context)[1];

  const handleDocument = async (data) => {
    if( data ) {
      dispatch({ type: 'UPDATE_DOCUMENT_DATA', payload: data})
      history.push(`/patient/${patientId || getReferralPatientId(referral)}/file/${data.document.id}/${data.iconName || 'file-alt'}`)
    }
  }

  return (
    <>
      <h1 style={{
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
      }}>
        Documents
      </h1>
      <ReferralDocumentList
        data={
          referral.linkedDocuments.map(
            document => DocumentUtils.getDisplayInfo(document)
          )
        }
        showDocument={handleDocument}
        onDocumentDelete={onDocumentDelete}
      />
    </>
  );
}
